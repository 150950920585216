.weather {
  flex-basis: 20rem;
}

.weather .input {
  max-width: 200px;
  font-size: var(--font-md);
  padding: 1.5rem var(--gap);
}

.weather .input:focus {
  border-color: var(--color-secondary);
}

.weather .section svg {
  width: 18px;
}

.weather p {
  font-size: var(--font-xsm);
  color: #9e9e9e;
}

.weather h4 {
  color: #00adf9;
  font-size: var(--font-xxlg);
}

.weather img {
  flex-shrink: 0;
}

.banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.1s linear;
}

.banner:hover img {
  transform: translateY(-2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
}

.currency {
  display: grid;
  grid-template-columns: 3fr 2fr;
  border: 1px solid #143797;
  border-left-width: 5px;
  align-items: center;
}

.currency > div,
.currency > ul {
  padding: 10px 20px;
}

.currency > ul {
  background-color: #143797;
  color: #fff;
  border: 1px solid #143797;
  border-left: none;
}

.currency h5 {
  color: var(--color-text-primary);
}

.currency h6 {
  color: #c2c2c2;
}

.currency h5,
.currency h6 {
  font-size: var(--font-sm);
  font-weight: 600;
  line-height: 1.3;
}

.currency ul li {
  font-size: var(--font-xsm);
  font-weight: 600;
}

.currency:hover h5,
.currency:hover h6 {
  text-decoration: underline;
}

.currency:hover h6 {
  color: #1675e0;
}
